import * as Sentry from '@sentry/gatsby'
import { BrowserTracing } from '@sentry/tracing'

import { config } from './src/config/global'

const { sentryDSN: dsn, version: release, env } = config
const eventFilter = event => {
  if (event.level === 'fatal' || event.level === 'error') {
    return event
  }
  return null
}

if (dsn) {
  Sentry.init({
    dsn,
    release,
    environment: env,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,
    beforeSend: eventFilter,
    beforeSendTransaction: eventFilter,
  })
}
